<template>
    <div class="hold-transition login-page bg-login view-user-activate">
        <div id="app" class="login-box">
            <div class="card">
                <div class="card-body login-card-body">
                    <div class="row">
                        <div class="col-12 text-center mb-3">
                            <img class="logo-login-img" src="@/assets/img/dentcloud-logo-darkblue.png">
                            <h3>DentCloud</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="activateStatus == true">
                            <h2 class="text-success"><i class="fas fa-check-circle"></i> บัญชีของท่านได้ถูกเปิดใช้งานแล้ว!!</h2>
                        </div>
                        <div class="col-12" v-if="activateStatus == false">
                            <h2 class="text-danger"><i class="fas fa-exclamation-triangle"></i> บัญชีของท่านไม่สามารถเปิดใช้งานได้!!</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a href="/login">ลงชื่อเข้าใช้</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: "ActivateViews",
	components: {
		 
	},
	data() {
		return {
            activate : {
                activateKey : ""
            },
            activateStatus : false,
        }
    },
    created() {
        this.activate.activateKey = this.$route.params.activateKey;
    },
    methods: {
        ...mapActions({
            checkUserActivate : "moduleUser/checkUserActivate",
        }),
        activateUser(){
            this.checkUserActivate(this.activate).then(res => {
                if(res.status == 200){
                    this.activateStatus = true;
                }
            }).catch(() => {
                this.activateStatus = false;
            });
        }
    },
    mounted() {
        this.activateUser();
    },
}
</script>
	
